<template>
  <div fp-request-reset>
    <ValidationObserver v-if="isLoad" tag="div" class="field-holder" ref="validator" v-slot="{ invalid }">
      <div class="content">
        <FpTitle :title="title" :back="false" />
        <p class="desc" v-if="desc" v-html="desc" />
        <FpReset :model-info="model" :structure="structure" />
      </div>
      <div class="bottom">
        <ProgressButton button-name="reset" :progress="isProgress" @click="submit" :disabled="invalid|| structure.error" />
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import Specific from '@shared/types/Specific';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import FpReset from '@/views/components/pages/forgot-password/template/FpReset.vue';
import { state } from '@shared/utils/storeUtils.mjs';
import FpTitle from '@/views/components/pages/forgot-password/FpTitle.vue';
import fpErrorHandler from '@/mixins/forgot-password/fpErrorHandler';
import { siteIds } from '@/constants/base/siteMap.mjs';
import { apiErrorCode } from '@/constants/base/apiErrorCode';
export default {
  name: 'FpRequestReset',
  mixins: [fpErrorHandler],
  components: { FpTitle, FpReset, ProgressButton },
  props: {
    structure: Specific,
  },
  data() {
    return {
      isProgress: false,
      isLoad: false,
      model: {
        VerificationToken: null,
        VerificationMethodType: 0,

        Email: null,
        IsTokenVerification: true,
        LoginMethod: 'Email',
        Password: null,
        RecaptchaAction: null,
        RecaptchaSiteKey: null,
        RecaptchaToken: null,
        Token: null,
      }
    };
  },
  computed: {
    brandId: state('env', 'gpBrand'),
    site: state('env', 'site'),
    siteInfo: state('env', 'siteInfo'),
    title() {
      return 'forgotPassword.title.resetPassword';
    },
    desc() {
      return this.$t('forgotPassword.description.enterPassword');
    },
    isMBP() { return [siteIds.GGPUKE, siteIds.EVPUKE].includes(this.site); },
  },
  methods: {
    async loadCaptcha() {
      this.captcha = {};
      // if (!this.isLocalOrTest || this.testCaptcha) this.isMBP ? await this.$services.captcha.setGeeCaptcha() : await this.$services.captcha.setRecaptcha(this.action);
      const action = 'ResetPasswordByEmail';
      this.captcha = this.isMBP ? await this.$services.captcha.setGeeCaptcha() : await this.$services.captcha.setRecaptcha(action);
    },
    async submit() {
      await this.loadCaptcha();
      this.isProgress = true;

      const result = await this.$services.sign.signResetPassword({ ...this.model, ...this.captcha });

      if (result.error) {

        this.apiErrorHandler(result);

        let key = result.key;
        this.structure.error = this.error.error;
        this.structure.errorMsg = this.error.errorMsg;
        // this.$t(key);

        return;
      }

      this.structure.success = true;
      this.structure.hasButton = false;
      await this.routeName('FpComplete');

    },
    async sendRequest() {
      const result = await this.$services.sign.requestVerificationToken(this.model);

      if (result.error) {
        this.structure.success = false;
        this.structure.hasButton = false;
        this.structure.completeReset = false;
        this.apiErrorHandler(result);
        if(result?.key === apiErrorCode.ALREADY_VERIFIED_EMAIL) {
          this.structure.error = this.error.error;
          this.structure.errorMsg = this.$t('RESET_PASSWORD_USED');
        }else {
          this.structure.error = this.error.error;
          this.structure.errorMsg = this.error.errorMsg;
        }

        this.setErrorTitle(result?.key);
        await this.routeName('FpComplete');
        return false;
      }

      this.model = {
        ...this.model,
        Email: result.Email,
        VerificationMethodType: this.model.LoginMethod,
        IsTokenVerification: true,
      };
      return true;
    },
    setErrorTitle(errorCode) {
      switch (errorCode) {
        case 'EXPIRED_VERIFICATIONTOKEN': {
          this.structure.errorTitle = 'forgotPassword.title.expired';
          break;
        }
        case 'ALREADY_VERIFIED_EMAIL': {
          this.structure.errorTitle = 'forgotPassword.title.used';
          break;
        }
        default: {
          this.structure.errorTitle = 'forgotPassword.title.error';
          break;
        }
      }
    },
  },
  async mounted() {
    const { token, VerificationMethodType } = this.$route.query;
    const verificationToken = token || null;
    const verificationMethodType = VerificationMethodType || null;

    if (!verificationToken) {
      this.structure.success = false;
      this.structure.hasButton = false;
      this.structure.completeReset = false;
      this.structure.error = true;
      this.structure.errorMsg = '';
      this.structure.errorTitle = 'forgotPassword.title.error';
      return await this.replaceRouteName('FpComplete');
    }

    this.model = {
      ...this.model,
      VerificationToken: verificationToken,
      Token: verificationToken,
      VerificationMethodType: verificationMethodType === 'Email' ? 0 : 1,
      LoginMethod: verificationMethodType,
    };

    if (this.model.VerificationToken) {
      const result = await this.sendRequest();
      if (result) {
        this.isLoad = true;
      }
    }
  },
  beforeDestroy() {
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[fp-request-reset] {.flex(); .flex-dc(); .h(100%);
  .field-holder {flex: 1; .flex(); .flex-dc();
    .content {flex: 1;}
    .bottom {.w(100%); .mb(100);
      [progress-button] {.max-w(100%);}
    }
  }
}
</style>